
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NewSignUpView extends Vue {
    checkoutSessionId = "";

    created() {
        this.checkoutSessionId = this.$route.query.checkoutSessionId as string;
    }
}
